// Generated by Framer (ccd740c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["P51KmABye"];

const variantClassNames = {P51KmABye: "framer-v-1q54o1t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "P51KmABye", tap: uBmh0hdeN, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "P51KmABye", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap14k0uqk = activeVariantCallback(async (...args) => {
if (uBmh0hdeN) {
const res = await uBmh0hdeN(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-NEWfP", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1q54o1t", className)} data-framer-name={"Active"} data-highlight layoutDependency={layoutDependency} layoutId={"P51KmABye"} onTap={onTap14k0uqk} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-NEWfP [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NEWfP * { box-sizing: border-box; }", ".framer-NEWfP .framer-xnomfa { display: block; }", ".framer-NEWfP .framer-1q54o1t { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; min-height: 19px; min-width: 55px; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-NEWfP .framer-1q54o1t { gap: 0px; } .framer-NEWfP .framer-1q54o1t > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-NEWfP .framer-1q54o1t > :first-child { margin-left: 0px; } .framer-NEWfP .framer-1q54o1t > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 55
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"uBmh0hdeN":"tap"}
 */
const FramerFQe2WHBXd: React.ComponentType<Props> = withCSS(Component, css, "framer-NEWfP") as typeof Component;
export default FramerFQe2WHBXd;

FramerFQe2WHBXd.displayName = "BreadCrumbItem";

FramerFQe2WHBXd.defaultProps = {height: 19, width: 55};

addPropertyControls(FramerFQe2WHBXd, {uBmh0hdeN: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerFQe2WHBXd, [])